import { default as _91_46_46_46slug_93AUTGYGR2pPMeta } from "/home/node/app/frontend/pages/[[center]]/[...slug].vue?macro=true";
import { default as blogs7fbD2Aw6HoMeta } from "/home/node/app/frontend/pages/[[center]]/blogs.vue?macro=true";
import { default as eventswFfL6jqkTUMeta } from "/home/node/app/frontend/pages/[[center]]/events.vue?macro=true";
import { default as experts2mA3RvJwxmMeta } from "/home/node/app/frontend/pages/[[center]]/experts.vue?macro=true";
import { default as _91slug_93Bh5qMLjOWFMeta } from "/home/node/app/frontend/pages/[[center]]/people/[slug].vue?macro=true";
import { default as podcastsjkhRP22JySMeta } from "/home/node/app/frontend/pages/[[center]]/podcasts.vue?macro=true";
import { default as programs_45and_45projectszeaI2WNj6iMeta } from "/home/node/app/frontend/pages/[[center]]/programs-and-projects.vue?macro=true";
import { default as _91slug_93YKiTDysVq7Meta } from "/home/node/app/frontend/pages/[[center]]/regions/[slug].vue?macro=true";
import { default as researchfDpm7SI4xmMeta } from "/home/node/app/frontend/pages/[[center]]/research.vue?macro=true";
import { default as search1N9CjeW0p5Meta } from "/home/node/app/frontend/pages/[[center]]/search.vue?macro=true";
import { default as staffax9FgZXGsKMeta } from "/home/node/app/frontend/pages/[[center]]/staff.vue?macro=true";
import { default as _91slug_93vJsMgGkPhxMeta } from "/home/node/app/frontend/pages/[[center]]/topics/[slug].vue?macro=true";
import { default as _91slug_93oMTinNDYroMeta } from "/home/node/app/frontend/pages/collections/[slug].vue?macro=true";
import { default as _91slug_93uRPcIDOHsBMeta } from "/home/node/app/frontend/pages/events/[slug].vue?macro=true";
import { default as _91slug_93d5BmDow27dMeta } from "/home/node/app/frontend/pages/events/[year]/[month]/[slug].vue?macro=true";
import { default as _91slug_93ZIXmb7SnrVMeta } from "/home/node/app/frontend/pages/features/[slug].vue?macro=true";
import { default as africa_45digital_45regulationsu3klzHgrpuMeta } from "/home/node/app/frontend/pages/features/africa-digital-regulations.vue?macro=true";
import { default as african_45climate_45research_45organizationsX7GWl3Olr1Meta } from "/home/node/app/frontend/pages/features/african-climate-research-organizations.vue?macro=true";
import { default as ai_45global_45surveillance_45technologyT69nYSK0kXMeta } from "/home/node/app/frontend/pages/features/ai-global-surveillance-technology.vue?macro=true";
import { default as back_45channelVEBeDAjpI8Meta } from "/home/node/app/frontend/pages/features/back-channel.vue?macro=true";
import { default as climate_45protest_45trackerrDrsopZKz5Meta } from "/home/node/app/frontend/pages/features/climate-protest-tracker.vue?macro=true";
import { default as commercial_45spywareA9YBA5kB97Meta } from "/home/node/app/frontend/pages/features/commercial-spyware.vue?macro=true";
import { default as fincyber_45timelinelndoG8EcUXMeta } from "/home/node/app/frontend/pages/features/fincyber-timeline.vue?macro=true";
import { default as global_45protest_45trackerijVvC3jhqMMeta } from "/home/node/app/frontend/pages/features/global-protest-tracker.vue?macro=true";
import { default as global_45russiaztstbAGDLFMeta } from "/home/node/app/frontend/pages/features/global-russia.vue?macro=true";
import { default as india_45elects_452024cgnynEK19rMeta } from "/home/node/app/frontend/pages/features/india-elects-2024.vue?macro=true";
import { default as navigating_45influence_45menaNeFKbyskKhMeta } from "/home/node/app/frontend/pages/features/navigating-influence-mena.vue?macro=true";
import { default as palestinian_45prisoner_45paymentscB7Sbi8zndMeta } from "/home/node/app/frontend/pages/features/palestinian-prisoner-payments.vue?macro=true";
import { default as radical_45right_45europe_45foreign_45policysF8L2sDTsmMeta } from "/home/node/app/frontend/pages/features/radical-right-europe-foreign-policy.vue?macro=true";
import { default as timeline_45of_45us_45policy_45toward_45palestineSUaAfjT8olMeta } from "/home/node/app/frontend/pages/features/timeline-of-us-policy-toward-palestine.vue?macro=true";
import { default as tunisian_45elections_452019zgvyiaADccMeta } from "/home/node/app/frontend/pages/features/tunisian-elections-2019.vue?macro=true";
import { default as _91slug_93GaA2QHLqzAMeta } from "/home/node/app/frontend/pages/in-the-media/[year]/[month]/[slug].vue?macro=true";
import { default as not_45foundOPmJIvEGfdMeta } from "/home/node/app/frontend/pages/not-found.vue?macro=true";
import { default as _91slug_93IPXqmMFTAAMeta } from "/home/node/app/frontend/pages/podcasts/[episode]/[slug].vue?macro=true";
import { default as _91slug_93mR8skP0d9hMeta } from "/home/node/app/frontend/pages/podcasts/[slug].vue?macro=true";
import { default as _91slug_93E1IY9DSaqsMeta } from "/home/node/app/frontend/pages/posts/[year]/[month]/[slug].vue?macro=true";
import { default as _91slug_93DrUrv5mEFNMeta } from "/home/node/app/frontend/pages/press/[year]/[month]/[slug].vue?macro=true";
import { default as _91slug_93HVGQQaTyztMeta } from "/home/node/app/frontend/pages/programs/[slug].vue?macro=true";
import { default as _91slug_93SwJYH7ImJeMeta } from "/home/node/app/frontend/pages/projects/[slug].vue?macro=true";
import { default as _91slug_93aKwpHKn5PQMeta } from "/home/node/app/frontend/pages/research/[year]/[month]/[slug].vue?macro=true";
import { default as _91slug_93YZ7FIXpcH1Meta } from "/home/node/app/frontend/pages/videos/[year]/[month]/[slug].vue?macro=true";
import { default as component_45stubI6LTk9GP5BMeta } from "/home/node/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.2_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_jq7chgbtrfqzbn3jqh4wcsidti/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubI6LTk9GP5B } from "/home/node/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.2_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_jq7chgbtrfqzbn3jqh4wcsidti/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "center-slug",
    path: "/:center?/:slug(.*)*",
    component: () => import("/home/node/app/frontend/pages/[[center]]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "center-blogs",
    path: "/:center?/blogs",
    component: () => import("/home/node/app/frontend/pages/[[center]]/blogs.vue").then(m => m.default || m)
  },
  {
    name: "center-events",
    path: "/:center?/events",
    component: () => import("/home/node/app/frontend/pages/[[center]]/events.vue").then(m => m.default || m)
  },
  {
    name: "center-experts",
    path: "/:center?/experts",
    component: () => import("/home/node/app/frontend/pages/[[center]]/experts.vue").then(m => m.default || m)
  },
  {
    name: "center-people-slug",
    path: "/:center?/people/:slug()",
    component: () => import("/home/node/app/frontend/pages/[[center]]/people/[slug].vue").then(m => m.default || m)
  },
  {
    name: "center-podcasts",
    path: "/:center?/podcasts",
    component: () => import("/home/node/app/frontend/pages/[[center]]/podcasts.vue").then(m => m.default || m)
  },
  {
    name: "center-programs-and-projects",
    path: "/:center?/programs-and-projects",
    component: () => import("/home/node/app/frontend/pages/[[center]]/programs-and-projects.vue").then(m => m.default || m)
  },
  {
    name: "center-regions-slug",
    path: "/:center?/regions/:slug()",
    component: () => import("/home/node/app/frontend/pages/[[center]]/regions/[slug].vue").then(m => m.default || m)
  },
  {
    name: "center-research",
    path: "/:center?/research",
    component: () => import("/home/node/app/frontend/pages/[[center]]/research.vue").then(m => m.default || m)
  },
  {
    name: "center-search",
    path: "/:center?/search",
    component: () => import("/home/node/app/frontend/pages/[[center]]/search.vue").then(m => m.default || m)
  },
  {
    name: "center-staff",
    path: "/:center?/staff",
    component: () => import("/home/node/app/frontend/pages/[[center]]/staff.vue").then(m => m.default || m)
  },
  {
    name: "center-topics-slug",
    path: "/:center?/topics/:slug()",
    component: () => import("/home/node/app/frontend/pages/[[center]]/topics/[slug].vue").then(m => m.default || m)
  },
  {
    name: "collections-slug",
    path: "/collections/:slug()",
    component: () => import("/home/node/app/frontend/pages/collections/[slug].vue").then(m => m.default || m)
  },
  {
    name: "events-slug",
    path: "/events/:slug()",
    component: () => import("/home/node/app/frontend/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: "events-year-month-slug",
    path: "/events/:year()/:month()/:slug()",
    component: () => import("/home/node/app/frontend/pages/events/[year]/[month]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "features-slug",
    path: "/features/:slug()",
    component: () => import("/home/node/app/frontend/pages/features/[slug].vue").then(m => m.default || m)
  },
  {
    name: "features-africa-digital-regulations",
    path: "/features/africa-digital-regulations",
    component: () => import("/home/node/app/frontend/pages/features/africa-digital-regulations.vue").then(m => m.default || m)
  },
  {
    name: "features-african-climate-research-organizations",
    path: "/features/african-climate-research-organizations",
    component: () => import("/home/node/app/frontend/pages/features/african-climate-research-organizations.vue").then(m => m.default || m)
  },
  {
    name: "features-ai-global-surveillance-technology",
    path: "/features/ai-global-surveillance-technology",
    component: () => import("/home/node/app/frontend/pages/features/ai-global-surveillance-technology.vue").then(m => m.default || m)
  },
  {
    name: "features-back-channel",
    path: "/features/back-channel",
    component: () => import("/home/node/app/frontend/pages/features/back-channel.vue").then(m => m.default || m)
  },
  {
    name: "features-climate-protest-tracker",
    path: "/features/climate-protest-tracker",
    component: () => import("/home/node/app/frontend/pages/features/climate-protest-tracker.vue").then(m => m.default || m)
  },
  {
    name: "features-commercial-spyware",
    path: "/features/commercial-spyware",
    component: () => import("/home/node/app/frontend/pages/features/commercial-spyware.vue").then(m => m.default || m)
  },
  {
    name: "features-fincyber-timeline",
    path: "/features/fincyber-timeline",
    component: () => import("/home/node/app/frontend/pages/features/fincyber-timeline.vue").then(m => m.default || m)
  },
  {
    name: "features-global-protest-tracker",
    path: "/features/global-protest-tracker",
    component: () => import("/home/node/app/frontend/pages/features/global-protest-tracker.vue").then(m => m.default || m)
  },
  {
    name: "features-global-russia",
    path: "/features/global-russia",
    component: () => import("/home/node/app/frontend/pages/features/global-russia.vue").then(m => m.default || m)
  },
  {
    name: "features-india-elects-2024",
    path: "/features/india-elects-2024",
    component: () => import("/home/node/app/frontend/pages/features/india-elects-2024.vue").then(m => m.default || m)
  },
  {
    name: "features-navigating-influence-mena",
    path: "/features/navigating-influence-mena",
    component: () => import("/home/node/app/frontend/pages/features/navigating-influence-mena.vue").then(m => m.default || m)
  },
  {
    name: "features-palestinian-prisoner-payments",
    path: "/features/palestinian-prisoner-payments",
    component: () => import("/home/node/app/frontend/pages/features/palestinian-prisoner-payments.vue").then(m => m.default || m)
  },
  {
    name: "features-radical-right-europe-foreign-policy",
    path: "/features/radical-right-europe-foreign-policy",
    component: () => import("/home/node/app/frontend/pages/features/radical-right-europe-foreign-policy.vue").then(m => m.default || m)
  },
  {
    name: "features-timeline-of-us-policy-toward-palestine",
    path: "/features/timeline-of-us-policy-toward-palestine",
    component: () => import("/home/node/app/frontend/pages/features/timeline-of-us-policy-toward-palestine.vue").then(m => m.default || m)
  },
  {
    name: "features-tunisian-elections-2019",
    path: "/features/tunisian-elections-2019",
    component: () => import("/home/node/app/frontend/pages/features/tunisian-elections-2019.vue").then(m => m.default || m)
  },
  {
    name: "in-the-media-year-month-slug",
    path: "/in-the-media/:year()/:month()/:slug()",
    component: () => import("/home/node/app/frontend/pages/in-the-media/[year]/[month]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "not-found",
    path: "/not-found",
    component: () => import("/home/node/app/frontend/pages/not-found.vue").then(m => m.default || m)
  },
  {
    name: "podcasts-episode-slug",
    path: "/podcasts/:episode()/:slug()",
    component: () => import("/home/node/app/frontend/pages/podcasts/[episode]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "podcasts-slug",
    path: "/podcasts/:slug()",
    component: () => import("/home/node/app/frontend/pages/podcasts/[slug].vue").then(m => m.default || m)
  },
  {
    name: "posts-year-month-slug",
    path: "/posts/:year()/:month()/:slug()",
    component: () => import("/home/node/app/frontend/pages/posts/[year]/[month]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "press-year-month-slug",
    path: "/press/:year()/:month()/:slug()",
    component: () => import("/home/node/app/frontend/pages/press/[year]/[month]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "programs-slug",
    path: "/programs/:slug()",
    component: () => import("/home/node/app/frontend/pages/programs/[slug].vue").then(m => m.default || m)
  },
  {
    name: "projects-slug",
    path: "/projects/:slug()",
    component: () => import("/home/node/app/frontend/pages/projects/[slug].vue").then(m => m.default || m)
  },
  {
    name: "research-year-month-slug",
    path: "/research/:year()/:month()/:slug()",
    component: () => import("/home/node/app/frontend/pages/research/[year]/[month]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "videos-year-month-slug",
    path: "/videos/:year()/:month()/:slug()",
    component: () => import("/home/node/app/frontend/pages/videos/[year]/[month]/[slug].vue").then(m => m.default || m)
  },
  {
    name: component_45stubI6LTk9GP5BMeta?.name,
    path: "/favicon/favicon-16x16.png",
    component: component_45stubI6LTk9GP5B
  },
  {
    name: component_45stubI6LTk9GP5BMeta?.name,
    path: "/favicon/favicon-32x32.png",
    component: component_45stubI6LTk9GP5B
  },
  {
    name: component_45stubI6LTk9GP5BMeta?.name,
    path: "/favicon/apple-icon-57x57.png",
    component: component_45stubI6LTk9GP5B
  },
  {
    name: component_45stubI6LTk9GP5BMeta?.name,
    path: "/favicon/apple-icon-60x60.png",
    component: component_45stubI6LTk9GP5B
  },
  {
    name: component_45stubI6LTk9GP5BMeta?.name,
    path: "/favicon/apple-icon-72x72.png",
    component: component_45stubI6LTk9GP5B
  },
  {
    name: component_45stubI6LTk9GP5BMeta?.name,
    path: "/favicon/apple-icon-76x76.png",
    component: component_45stubI6LTk9GP5B
  },
  {
    name: component_45stubI6LTk9GP5BMeta?.name,
    path: "/favicon/apple-icon-114x114.png",
    component: component_45stubI6LTk9GP5B
  },
  {
    name: component_45stubI6LTk9GP5BMeta?.name,
    path: "/favicon/apple-icon-120x120.png",
    component: component_45stubI6LTk9GP5B
  },
  {
    name: component_45stubI6LTk9GP5BMeta?.name,
    path: "/favicon/apple-icon-144x144.png",
    component: component_45stubI6LTk9GP5B
  },
  {
    name: component_45stubI6LTk9GP5BMeta?.name,
    path: "/favicon/apple-icon-152x152.png",
    component: component_45stubI6LTk9GP5B
  },
  {
    name: component_45stubI6LTk9GP5BMeta?.name,
    path: "/favicon/apple-icon-180x180.png",
    component: component_45stubI6LTk9GP5B
  },
  {
    name: component_45stubI6LTk9GP5BMeta?.name,
    path: "/favicon/android-icon-192x192.png",
    component: component_45stubI6LTk9GP5B
  },
  {
    name: component_45stubI6LTk9GP5BMeta?.name,
    path: "/favicon/android-icon-36x36.png",
    component: component_45stubI6LTk9GP5B
  },
  {
    name: component_45stubI6LTk9GP5BMeta?.name,
    path: "/favicon/android-icon-48x48.png",
    component: component_45stubI6LTk9GP5B
  },
  {
    name: component_45stubI6LTk9GP5BMeta?.name,
    path: "/favicon/android-icon-72x72.png",
    component: component_45stubI6LTk9GP5B
  },
  {
    name: component_45stubI6LTk9GP5BMeta?.name,
    path: "/favicon/android-icon-96x96.png",
    component: component_45stubI6LTk9GP5B
  },
  {
    name: component_45stubI6LTk9GP5BMeta?.name,
    path: "/favicon/android-icon-144x144.png",
    component: component_45stubI6LTk9GP5B
  },
  {
    name: component_45stubI6LTk9GP5BMeta?.name,
    path: "/favicon/favicon-96x96.png",
    component: component_45stubI6LTk9GP5B
  },
  {
    name: component_45stubI6LTk9GP5BMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubI6LTk9GP5B
  },
  {
    name: component_45stubI6LTk9GP5BMeta?.name,
    path: "/collections-sitemap.xml",
    component: component_45stubI6LTk9GP5B
  },
  {
    name: component_45stubI6LTk9GP5BMeta?.name,
    path: "/events-sitemap.xml",
    component: component_45stubI6LTk9GP5B
  },
  {
    name: component_45stubI6LTk9GP5BMeta?.name,
    path: "/eventCollections-sitemap.xml",
    component: component_45stubI6LTk9GP5B
  },
  {
    name: component_45stubI6LTk9GP5BMeta?.name,
    path: "/editions-sitemap.xml",
    component: component_45stubI6LTk9GP5B
  },
  {
    name: component_45stubI6LTk9GP5BMeta?.name,
    path: "/features-sitemap.xml",
    component: component_45stubI6LTk9GP5B
  },
  {
    name: component_45stubI6LTk9GP5BMeta?.name,
    path: "/newsletters-sitemap.xml",
    component: component_45stubI6LTk9GP5B
  },
  {
    name: component_45stubI6LTk9GP5BMeta?.name,
    path: "/people-sitemap.xml",
    component: component_45stubI6LTk9GP5B
  },
  {
    name: component_45stubI6LTk9GP5BMeta?.name,
    path: "/podcastEpisodes-sitemap.xml",
    component: component_45stubI6LTk9GP5B
  },
  {
    name: component_45stubI6LTk9GP5BMeta?.name,
    path: "/podcasts-sitemap.xml",
    component: component_45stubI6LTk9GP5B
  },
  {
    name: component_45stubI6LTk9GP5BMeta?.name,
    path: "/posts-sitemap.xml",
    component: component_45stubI6LTk9GP5B
  },
  {
    name: component_45stubI6LTk9GP5BMeta?.name,
    path: "/research-sitemap.xml",
    component: component_45stubI6LTk9GP5B
  },
  {
    name: component_45stubI6LTk9GP5BMeta?.name,
    path: "/videos-sitemap.xml",
    component: component_45stubI6LTk9GP5B
  },
  {
    name: component_45stubI6LTk9GP5BMeta?.name,
    path: "/blogs-sitemap.xml",
    component: component_45stubI6LTk9GP5B
  },
  {
    name: component_45stubI6LTk9GP5BMeta?.name,
    path: "/blogsLanding-sitemap.xml",
    component: component_45stubI6LTk9GP5B
  },
  {
    name: component_45stubI6LTk9GP5BMeta?.name,
    path: "/centers-sitemap.xml",
    component: component_45stubI6LTk9GP5B
  },
  {
    name: component_45stubI6LTk9GP5BMeta?.name,
    path: "/directories-sitemap.xml",
    component: component_45stubI6LTk9GP5B
  },
  {
    name: component_45stubI6LTk9GP5BMeta?.name,
    path: "/donationPortals-sitemap.xml",
    component: component_45stubI6LTk9GP5B
  },
  {
    name: component_45stubI6LTk9GP5BMeta?.name,
    path: "/eventsLanding-sitemap.xml",
    component: component_45stubI6LTk9GP5B
  },
  {
    name: component_45stubI6LTk9GP5BMeta?.name,
    path: "/expertsLanding-sitemap.xml",
    component: component_45stubI6LTk9GP5B
  },
  {
    name: component_45stubI6LTk9GP5BMeta?.name,
    path: "/informationals-sitemap.xml",
    component: component_45stubI6LTk9GP5B
  },
  {
    name: component_45stubI6LTk9GP5BMeta?.name,
    path: "/podcastLanding-sitemap.xml",
    component: component_45stubI6LTk9GP5B
  },
  {
    name: component_45stubI6LTk9GP5BMeta?.name,
    path: "/programs-sitemap.xml",
    component: component_45stubI6LTk9GP5B
  },
  {
    name: component_45stubI6LTk9GP5BMeta?.name,
    path: "/programsProjectLanding-sitemap.xml",
    component: component_45stubI6LTk9GP5B
  },
  {
    name: component_45stubI6LTk9GP5BMeta?.name,
    path: "/projects-sitemap.xml",
    component: component_45stubI6LTk9GP5B
  },
  {
    name: component_45stubI6LTk9GP5BMeta?.name,
    path: "/researchLanding-sitemap.xml",
    component: component_45stubI6LTk9GP5B
  },
  {
    name: component_45stubI6LTk9GP5BMeta?.name,
    path: "/staff-sitemap.xml",
    component: component_45stubI6LTk9GP5B
  }
]