<template>
    <div class="video-block" ref="videoParent">
        <client-only>
            <div
                class="wrapper"
                :class="{ 'wrapper-fixed-ratio': preserveAspectRatio }"
                :style="{ height: 0.05625 * videoWidth + 'rem' }"
            >
                <iframe
                    v-if="videoId && !isPosterActive"
                    :src="`https://www.youtube.com/embed/${videoId}?&autoplay=1`"
                    class="video"
                    title="Video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; fullscreen; picture-in-picture"
                    allowfullscreen
                ></iframe>

                <a
                    :href="openInNewTab ? videoUrl : 'javascript:void(0)'"
                    :target="openInNewTab ? '_blank' : ''"
                    v-if="isPosterActive"
                    class="video-play"
                >
                    <img
                        @click="onPosterClick()"
                        class="poster-image"
                        :src="videoPosterImage?.url"
                        :alt="videoPosterImage?.altText"
                        :width="videoPosterImage?.width"
                        :height="videoPosterImage?.height"
                    />
                    <button class="overlay" @click="onPosterClick()">
                        <Icon name="video" size="large" class="play-icon" />
                    </button>
                </a>
            </div>
        </client-only>
    </div>
</template>

<script setup>
import Icon from '../elements/Icon.vue';
const props = defineProps({
    youtubeUrl: {
        url: {
            type: String,
            default: '',
        },
        newTab: {
            type: Boolean,
            default: false,
        },
    },
    url: {
        type: String,
        default: '',
    },
    altText: {
        type: String,
        default: '',
    },
    width: {
        type: Number,
    },
    height: {
        type: Number,
    },
    preserveAspectRatio: {
        type: Boolean,
        default: false,
    },
});

const isPosterActive = ref(true);
const videoParent = ref(null);

const videoUrl = props.youtubeUrl?.url;
const openInNewTab = props.youtubeUrl?.newTab;
let videoId = videoUrl ? videoUrl.substring(videoUrl.lastIndexOf('/') + 1).replace('watch?v=', '') : '';
if (videoUrl.includes("/live/")) {
    videoId = videoUrl ? videoUrl.substring(videoUrl.lastIndexOf('/') + 1) : '';
}

const videoPosterImage = {
    url: props.url,
    altText: props.altText,
    width: props.width,
    height: props.height,
};

const onPosterClick = () => {
    if (!props.youtubeUrl?.newTab) {
        isPosterActive.value = false;
    }
};

const videoWidth = computed(() => {
    return videoParent?.value?.offsetWidth;
});
</script>

<style lang="scss" scoped>
.wrapper {
    position: relative;
    width: 100%;
    height: 32.4rem;
    max-height: 100%;

    &.wrapper-fixed-ratio {
        // 56.25% is 9/16 aspect ratio
        height: unset;
        max-height: calc(0.5625 * 100vw - 2 * (var(--page-margin)));
    }
}

.video {
    width: 100%;
    height: 100%;
}

.poster-image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(45deg, color(noir, default, 0.8), transparent);
    @include z-index(999999999999);

    .play-icon {
        position: absolute;
        inset-block-end: 2.088rem;
        inset-inline-start: 2.088rem;
        color: color(blanc);

        @include square(5.5rem);

        @include media-query(tablet) {
            @include square(3.6rem);
        }

        @include media-query(phone) {
            @include square(1.8rem);
        }

        svg {
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }
}
</style>
