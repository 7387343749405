import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "109",
  height: "88",
  fill: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M7.783 67.495c-4.338 0-7.017-2.934-7.017-7.528 0-4.465 2.807-7.527 7.145-7.527 1.53 0 2.807.382 3.827.893l-.51 3.19c-.893-.639-1.914-1.149-3.19-1.149-2.296 0-3.7 1.786-3.7 4.466 0 2.807 1.531 4.465 3.828 4.465 1.148 0 2.169-.382 3.317-1.148l.51 3.062c-1.148.766-2.424 1.276-4.21 1.276M19.776 52.694h3.956l5.103 14.163-3.7.638-1.02-3.19h-4.977l-1.02 3.062h-3.573zm.128 8.676h3.317l-1.658-5.103zM39.553 67.367l-3.19-5.103h-.893v4.976h-3.572V52.694H37c3.573 0 5.614 1.659 5.614 4.594 0 2.041-1.02 3.445-2.68 4.083l3.573 5.358zm-2.935-11.993H35.47v4.21h1.148c1.531 0 2.424-.765 2.424-2.169 0-1.276-.893-2.041-2.424-2.041M55.502 67.24l-5.742-8.676v8.676h-3.317V52.694h3.445l5.358 8.166v-8.166h3.318V67.24zM62.774 67.24V52.694h9.06v2.935h-5.487v2.68h4.21l.51 2.934h-4.72v3.062h5.742v2.935zM82.295 67.495c-4.593 0-7.145-3.062-7.145-7.528s2.807-7.527 7.273-7.527c1.786 0 3.317.382 4.466 1.02l-.51 3.19c-1.149-.638-2.425-1.276-3.828-1.276-2.552 0-3.7 1.914-3.7 4.593s1.275 4.594 3.7 4.594c.765 0 1.403-.128 1.913-.51v-2.553h-2.551l-.383-2.551h6.124v7.017q-2.487 1.53-5.359 1.531M91.61 67.24V52.694h3.572V67.24zM99.52 67.24V52.694h9.059v2.935h-5.486v2.68h4.21l.511 2.934h-4.721v3.062h5.741v2.935zM0 19.01C0 29.6 8.549 38.023 19.01 38.023V0C8.55 0 0 8.549 0 19.01M19.01 38.022h19.012V0H19.01v.128c10.462 0 18.883 8.548 18.883 18.883-.127 10.462-8.548 18.883-18.883 19.01M3.062 86.889V73.747h1.786V86.89zM18.883 86.889 11.61 76.682v10.207H9.952V73.747h1.658l7.018 9.825v-9.825h1.658V86.89zM29.09 86.889h-3.7V73.747h3.828c4.593 0 6.89 2.68 6.89 6.507-.128 3.955-2.552 6.635-7.017 6.635m.128-11.61h-2.041v9.951h2.041c3.19 0 5.104-1.659 5.104-4.976-.128-3.317-2.17-4.976-5.104-4.976M40.701 86.889V73.747h1.786V86.89zM51.802 73.62h1.786l5.103 13.013-1.786.383-1.531-4.083h-5.359l-1.53 3.955H46.57zm-1.276 7.654h4.21l-2.041-5.486z" }, null, -1)
  ])))
}
export default { render: render }