export default defineNuxtRouteMiddleware((to, from) => {
    const startsWith = ['/rss', '/email', '/admin', '/publications', '/RvDsovJJBnD73QSynZZcbNK'];
    for (const match of startsWith) {
        if (to.path.startsWith(match)) {
            return navigateTo('/not-found');
        }
    }

    const matches = ['wp-includes', '.php', '.env', 'wp-admin'];
    for (const match of matches) {
        if (to.path.includes(match)) {
            return navigateTo('/not-found');
        }
    }
});
