import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "135",
  fill: "currentColor",
  viewBox: "0 0 135 33"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M49.852 14.848c-3.641 0-5.865-2.438-5.865-6.305s2.32-6.337 6.005-6.337c1.289 0 2.342.279 3.222.794l-.43 2.729c-.73-.57-1.578-.988-2.642-.988-1.912 0-3.136 1.45-3.136 3.791s1.289 3.792 3.2 3.792c.989 0 1.88-.376 2.783-.934l.387 2.535c-.978.569-2.106.934-3.534.934zM59.895 2.367h3.33l4.254 11.89-3.061.495-.903-2.653h-4.21l-.817 2.535h-2.975l4.393-12.267zm.13 7.368h2.738L61.4 5.493l-1.386 4.242zM76.567 14.763l-2.675-4.319h-.762v4.2h-3.008V2.42h4.264c2.954 0 4.695 1.343 4.695 3.857 0 1.697-.849 2.846-2.3 3.47l2.998 4.5-3.201.515zM74.086 4.708h-.978v3.556h.935c1.31 0 2.051-.634 2.051-1.805s-.709-1.75-2.019-1.75zM89.972 14.634 85.16 7.319v7.315h-2.814V2.42h2.932l4.5 6.832V2.42h2.815v12.214zM96.148 14.634V2.42h7.616V4.86h-4.64v2.298h3.545l.386 2.428h-3.931v2.589h4.801v2.46h-7.776M112.53 14.87c-3.878 0-6.015-2.546-6.015-6.316s2.32-6.36 6.09-6.36c1.504 0 2.761.355 3.738.903l-.408 2.643c-.924-.58-1.998-1.042-3.255-1.042-2.105 0-3.136 1.557-3.136 3.845s1.063 3.91 3.169 3.91a3.4 3.4 0 0 0 1.643-.387v-2.18h-2.127l-.354-2.245h5.113v5.95c-1.279.828-2.782 1.29-4.458 1.29zM120.425 14.634V2.42h3.029v12.214zM127.074 14.634V2.42h7.616V4.86h-4.64v2.298h3.544l.387 2.428h-3.931v2.589h4.801v2.46h-7.777M0 16.868v.075c.043 8.819 7.208 15.973 16.048 15.973V.895C7.208.895.043 8.038 0 16.868" }, null, -1),
    _createElementVNode("path", { d: "M15.994 32.905H32.01V.895H16.005v.054C24.77.959 31.87 8.135 31.87 16.9s-7.1 15.92-15.865 15.92v.085zM49.358 31.54c-3.126 0-5.134-2.32-5.134-5.757s2.04-5.779 5.166-5.779c1.322 0 2.31.333 3.158.913l-.225 1.547c-.806-.612-1.762-1.074-2.954-1.074-2.256 0-3.588 1.708-3.588 4.383s1.354 4.36 3.588 4.36c1.192 0 2.234-.461 3.126-1.106l.225 1.429c-.86.623-2.094 1.074-3.373 1.074zM62.892 31.326v-5.124h-5.618v5.124H55.76V20.21h1.514v4.608h5.618v-4.608h1.515v11.117zM68.189 31.326V20.21h1.514v11.117zM80.96 31.326l-6.102-8.647v8.647h-1.396V20.21h1.353l5.898 8.324V20.21h1.396v11.117zM89.21 20.166h1.514l4.286 10.956-1.504.322-1.3-3.448h-4.522l-1.235 3.33H84.87l4.35-11.16zm-1.042 6.488h3.544l-1.772-4.727z" }, null, -1)
  ])))
}
export default { render: render }